<template>
  <surround-box  
    specialColor="#105570"
    background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)"
     title="存栏上报记录" style="width: 100%">
    <div class="record-box">
      <Table
        :data="table.data"
        :columns="table.columns"
        :height="170"
        disabled-hover
        highlight-row
      ></Table>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      table: {
        data: [],
        columns: [
          {
            title: "企业",
            align: "center",
            key: "farmName",
            tooltip: true,
          },
          {
            title: "存栏(头)",
            align: "center",
            key: "value",
          },
          {
            title: "时间",
            align: "center",
            key: "date",
          },
        ],
      },
      date: "",
      year: "",
      month: "",
      day: "",
    };
  },

  methods: {
    getList() {
      this.$post(this.$api.LIVESTOCK_ON_HAND.REPORT).then((res) => {
        let data = res.farm;
        let keys = Object.keys(data);
        let total = Math.ceil(keys.length / 3);
        if (total < 2) {
          this.table.data = keys.map((item) => ({
            farmName: item,
            value: data[item],
            date: this.date,
          }));
          return;
        }
        let temp = 1;
        let tempData = [];
        for (let i = (temp - 1) * 3; i < temp * 3 && i < keys.length; i++) {
          tempData.push({
            date: this.date,
            farmName: keys[i],
            value: data[keys[i]],
          });
        }
        this.table.data = tempData;
        let changeInterval = setInterval(() => {
          if (temp + 1 > total) temp = 1;
          else temp += 1;
          tempData = [];
          for (let i = (temp - 1) * 3; i < temp * 3 && i < keys.length; i++) {
            tempData.push({
              date: this.date,
              farmName: keys[i],
              value: data[keys[i]],
            });
          }
          this.table.data = tempData;
        }, 5000);
        this.$once("hook:beforeDestroy", () => {
          clearInterval(changeInterval);
        });
        // for (let key in data) {
        //   this.table.data.push({
        //     farmName: key,
        //     value: data[key],
        //     date: this.date,
        //   });
        // }
      });
    },
    getTime() {
      let dates = new Date();
      this.year = dates.getFullYear();
      if (Number(dates.getMonth() + 1) < 10) {
        this.month = "0" + Number(dates.getMonth() + 1);
      } else {
        this.month = Number(dates.getMonth() + 1);
      }
      if (dates.getDate() < 10) {
        this.day = "0" + dates.getDate();
      } else {
        this.day = dates.getDate();
      }
      this.date = this.year + "-" + this.month + "-" + this.day;
    },
  },
  mounted() {
    this.getList();
    this.getTime();
  },
};
</script>
<style lang='less' scoped>
/deep/ .ivu-table {
  background-color: transparent;
}

/deep/ .ivu-table th {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}
/deep/ .ivu-table td {
  background: transparent;
  color: #fff;
}
/deep/.ivu-table th {
  border-bottom: none;
}
/deep/ .ivu-table td {
  border-bottom: none;
}
/deep/ .ivu-table:before {
  background-color: transparent;
}
/deep/ .ivu-table-overflowY {
  &::-webkit-scrollbar {
    display: none;
  }
}
.record-box {
  // width: 100%;
  height: 220px;
  position: relative;
  padding: 10px;
}
</style>